import axios from 'axios';
import React, { useState } from 'react';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [folderId, setFolderId] = useState(''); // New state for folder ID
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFolderIdChange = (event) => {
        setFolderId(event.target.value);
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file to upload.');
            return;
        }

        if (!folderId) {
            setMessage('Please enter a folder ID.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder_id', folderId);  // Add folder ID to form data

        try {
            setUploading(true);
            setMessage('Uploading...');
            const response = await axios.post('https://instavid.webys.org/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUploading(false);
            setMessage(`File uploaded successfully. File ID: ${response.data.file_id}`);
        } catch (error) {
            setUploading(false);
            setMessage('Error uploading file.');
            console.error('Upload error:', error);
        }
    };

    return (
        <div>
            <h2>Upload File</h2>
            <input type="file" onChange={handleFileChange} />
            <input
                type="text"
                placeholder="Enter Google Drive Folder ID"
                value={folderId}
                onChange={handleFolderIdChange}
            />
            <button onClick={handleUpload} disabled={uploading}>
                {uploading ? 'Uploading...' : 'Upload'}
            </button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default FileUpload;
