import axios from 'axios';
import React, { useState } from 'react';

const EditFile = () => {
    const [fileId, setFileId] = useState('');
    const [newName, setNewName] = useState('');
    const [fileUrl, setFileUrl] = useState(null);

    const handleEditFile = async () => {
        if (!fileId || !newName) {
            alert('File ID and new name are required');
            return;
        }

        try {
            const response = await axios.patch('https://instavid.webys.org/edit_file', {
                file_id: fileId,
                new_name: newName,
            });
            alert('File renamed with ID: ' + response.data.file_id);
        } catch (error) {
            console.error('Error renaming file:', error);
            alert('Failed to rename file');
        }
    };

    const handleViewFile = async () => {
        if (!fileId) {
            alert('File ID is required to view');
            return;
        }

        try {
            const response = await axios.get(`https://instavid.webys.org/view/${fileId}`, { responseType: 'blob' });
            const url = URL.createObjectURL(response.data);
            setFileUrl(url);
        } catch (error) {
            console.error('Error viewing file:', error);
            alert('Failed to view file');
        }
    };

    return (
        <div>
            <h2>Edit/View File/Folder</h2>
            <input
                type="text"
                placeholder="File/Folder ID"
                value={fileId}
                onChange={(e) => setFileId(e.target.value)}
            />
            <input
                type="text"
                placeholder="New Name"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
            />
            <button onClick={handleEditFile}>Rename</button>
            <button onClick={handleViewFile}>View</button>
            {fileUrl && (
                <div>
                    <h3>File Preview:</h3>
                    <iframe src={fileUrl} width="600" height="400" title="File Preview"></iframe>
                </div>
            )}
        </div>
    );
};

export default EditFile;
