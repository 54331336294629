import React, { useState } from 'react';
import axios from 'axios';

const CopyFile = () => {
    const [fileId, setFileId] = useState('');
    const [parentFolderId, setParentFolderId] = useState('');

    const handleCopyFile = async () => {
        if (!fileId || !parentFolderId) {
            alert('File ID and destination folder ID are required');
            return;
        }

        try {
            const response = await axios.post('https://instavid.webys.org/copy_file', {
                file_id: fileId,
                parent_folder_id: parentFolderId,
            });
            alert('File copied with ID: ' + response.data.file_id);
        } catch (error) {
            console.error('Error copying file:', error);
            alert('Failed to copy file');
        }
    };

    return (
        <div>
            <h2>Copy File/Folder</h2>
            <input
                type="text"
                placeholder="File/Folder ID"
                value={fileId}
                onChange={(e) => setFileId(e.target.value)}
            />
            <input
                type="text"
                placeholder="Destination Folder ID"
                value={parentFolderId}
                onChange={(e) => setParentFolderId(e.target.value)}
            />
            <button onClick={handleCopyFile}>Copy</button>
        </div>
    );
};

export default CopyFile;
