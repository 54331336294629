import axios from 'axios';
import React, { useEffect, useState } from 'react';

const FilesList = () => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            const response = await axios.get('https://instavid.webys.org/files');
            setFiles(response.data);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const handleDownloadFile = (fileId, fileName) => {
        axios({
            url: `https://instavid.webys.org/download/${fileId}`,
            method: 'GET',
            responseType: 'blob', // Important for downloading files
        })
        .then((response) => {
            // Create a URL and link to download the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((error) => {
            console.error('Error downloading file:', error);
            alert('Failed to download file');
        });
    };

    const handleViewFile = async (fileId) => {
        try {
            const response = await axios.get(`https://instavid.webys.org/view/${fileId}`, {
                responseType: 'blob' // Important for viewing files
            });
    
            const contentType = response.headers['content-type'];
    
            if (contentType.startsWith('image/') || contentType.startsWith('application/pdf')) {
                // Handle images and PDFs
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                window.open(url, '_blank'); // Open the file in a new tab
            } else if (contentType.startsWith('video/')) {
                // Handle videos
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                const videoWindow = window.open('', '_blank');
                videoWindow.document.write(`
                    <video controls autoplay style="width:100%; height:100%;">
                        <source src="${url}" type="${contentType}">
                        Your browser does not support the video tag.
                    </video>
                `);
            } else {
                alert('File type not supported for viewing');
            }
        } catch (error) {
            console.error('Error viewing file:', error);
            alert('Failed to view file');
        }
    };

    const handleDeleteFile = async (fileId) => {
        if (!window.confirm('Are you sure you want to delete this file?')) return;

        try {
            await axios.delete(`https://instavid.webys.org/delete_file`, {
                params: { file_id: fileId },
            });
            alert('File deleted successfully');
            fetchFiles();  // Refresh the file list
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Failed to delete file');
        }
    };

    return (
        <div>
            <h2>Files List</h2>
            <ul>
                {files.map((file) => (
                    <li key={file.id}>
                        {file.name}
                        <button 
                            onClick={() => handleDownloadFile(file.id, file.name)} 
                            style={{ marginLeft: '10px' }}
                        >
                            Download
                        </button>
                        <button 
                            onClick={() => handleViewFile(file.id)} 
                            style={{ marginLeft: '10px' }}
                        >
                            View
                        </button>
                        <button 
                            onClick={() => handleDeleteFile(file.id)} 
                            style={{ marginLeft: '10px' }}
                        >
                            Delete
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FilesList;
