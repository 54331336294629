import React from 'react';
import CopyFile from './components/CopyFile';
import CreateFolder from './components/CreateFolder';
import DeleteFile from './components/DeleteFile';
import EditFile from './components/EditFile';
import FileList from './components/FileList';
import FileUpload from './components/FileUpload';
import StorageUsage from './components/StorageUsage';

function App() {
    return (
        <div className="App">
            <header>
                <h1>Google Drive Manager</h1>
                <StorageUsage />
            </header>
            <main>
                <section className="actions-section">
                    <FileUpload />
                    <CreateFolder parentFolderId="" />
                    <DeleteFile />
                    <EditFile />
                    <CopyFile />
                </section>
                <section className="file-list-section">
                    <FileList />
                </section>
            </main>
        </div>
    );
}

export default App;
