import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StorageUsage = () => {
    const [usedSpace, setUsedSpace] = useState(0);
    const [totalSpace, setTotalSpace] = useState(75); // 75 GB is the total storage space

    useEffect(() => {
        // Fetch the storage usage data from the Flask backend
        const fetchStorageUsage = async () => {
            try {
                const response = await axios.get('https://instavid.webys.org/storage');
                const { used_space_gb, total_space_gb } = response.data;
                setUsedSpace(used_space_gb);
                setTotalSpace(total_space_gb);
            } catch (error) {
                console.error("Error fetching storage usage data:", error);
            }
        };

        fetchStorageUsage();
    }, []);

    const percentageUsed = (usedSpace / totalSpace) * 100;

    return (
        <div>
            <h3>Storage Usage</h3>
            <div style={{ height: '30px', width: '100%', backgroundColor: '#e0e0df', borderRadius: '5px' }}>
                <div
                    style={{
                        height: '100%',
                        width: `${percentageUsed}%`,
                        backgroundColor: percentageUsed > 80 ? 'red' : '#76c7c0',
                        borderRadius: 'inherit',
                        textAlign: 'center',
                        color: 'white',
                    }}
                >
                    {usedSpace.toFixed(2)} GB / {totalSpace.toFixed(2)} GB
                </div>
            </div>
        </div>
    );
};

export default StorageUsage;
