import React, { useState } from 'react';
import axios from 'axios';

const DeleteFile = () => {
    const [fileId, setFileId] = useState('');

    const handleDeleteFile = async () => {
        if (!fileId) {
            alert('File ID is required');
            return;
        }

        try {
            const response = await axios.delete('https://instavid.webys.org/delete_file', {
                params: {
                    file_id: fileId,
                },
            });
            alert('File deleted successfully');
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Failed to delete file');
        }
    };

    return (
        <div>
            <h2>Delete File/Folder</h2>
            <input
                type="text"
                placeholder="File/Folder ID"
                value={fileId}
                onChange={(e) => setFileId(e.target.value)}
            />
            <button onClick={handleDeleteFile}>Delete</button>
        </div>
    );
};

export default DeleteFile;
