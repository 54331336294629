import axios from 'axios';
import React, { useState } from 'react';

const CreateFolder = () => {
    const [folderName, setFolderName] = useState('');

    const handleCreateFolder = async () => {
        if (!folderName) {
            alert('Folder name is required');
            return;
        }

        try {
            const response = await axios.post('https://instavid.webys.org/create_folder', {
                folder_name: folderName,
            });
            alert('Folder created with ID: ' + response.data.folder_id);
        } catch (error) {
            console.error('Error creating folder:', error);
            alert('Failed to create folder');
        }
    };

    return (
        <div>
            <input
                type="text"
                placeholder="Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
            />
            <button onClick={handleCreateFolder}>Create Folder</button>
        </div>
    );
};

export default CreateFolder;
